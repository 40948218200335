const DEFAULT_ROUTE_AFTER_LOGIN = {name: 'TimeTracking'}
const DEFAULT_TITLE = 'LOOP.Intranet'

const HEADER_ID = 'intranet-header'

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'access_token'
const LOCAL_STORAGE_LAST_TASKPLAN_GROUP_KEY = 'loop-intranet-last-taskplan-group'

const DND_TYPES = {
  TASKPLAN_TASK: 'taskplan-task',
  TASK_DRAFT: 'task-draft',
  HANDBOOK_ARTICLE_ITEM: 'handbook-article-item',
}

const TASK_TYPES = {
  TASK: 'task',
  VACATION: 'vacation',
  SICK: 'sick',
  OUT_OF_OFFICE: 'outOfOffice',
  BUFFER_JUMBLE: 'bufferJumble',
  HOLIDAY: 'holiday',
  CARE_LEAVE: 'careLeave',
  COMPENSATION_DAY: 'compensationDay',
}

const TASK_TYPE_IDS = {
  [TASK_TYPES.TASK]: 1,
  [TASK_TYPES.VACATION]: 2,
  [TASK_TYPES.SICK]: 3,
  [TASK_TYPES.OUT_OF_OFFICE]: 4,
  [TASK_TYPES.BUFFER_JUMBLE]: 5,
  [TASK_TYPES.HOLIDAY]: 6,
  [TASK_TYPES.CARE_LEAVE]: 7,
  [TASK_TYPES.COMPENSATION_DAY]: 8,
}

const QUOTE_STATUSES = {
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  REVISED: 'revised',
}

const LEGACY_QUOTE_TOOL_URL = `https://quotes.agentur-loop.com/`

const JOB_CARRY_OVER_FIELDS = [
  'carryOverYear1',
  'carryOverYear2',
  'carryOverYear3',
  'carryOverPercentage1',
  'carryOverPercentage2',
  'carryOverPercentage3',
]

const HANDBOOK_ARTICLE_ITEM_TYPES = {
  FILE: 4,
  HTML: 3,
  IMAGE: 2,
}

/* eslint-disable camelcase */
const EXTERNAL_COSTS_LABELS = {
  pass_to_client: '1:1 billed to client',
  included_in_quote: 'Paid from project budget',
}
/* eslint-enable camelcase */

const SUBMITTED_RECEIPT_BOOKING_FORM_FIELDS = [
  'id',
  'account',
  'contraAccount',
  'amount',
  'taxRate',
  'taxAmount',
  'taxCode',
]

const SUBMITTED_RECEIPT_STATUS = {
  EXPORTED: 'exported',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: 'pending',
  IN_REVIEW: 'in_review',
}

const SUBMITTED_RECEIPT_STATUS_LABELS = {
  [SUBMITTED_RECEIPT_STATUS.PENDING]: 'Pending',
  [SUBMITTED_RECEIPT_STATUS.IN_REVIEW]: 'In review',
  [SUBMITTED_RECEIPT_STATUS.APPROVED]: 'Approved',
  [SUBMITTED_RECEIPT_STATUS.REJECTED]: 'Rejected',
  [SUBMITTED_RECEIPT_STATUS.EXPORTED]: 'Payout initiated',
}

// Actions for permission management
const ACTIONS = {
  // Excel Permissions
  DOWNLOAD_EXCEL: 'download_excel',

  // Scheduled Task Permissions / Taskplan Tasks
  VIEW_ANY_SCHEDULED_TASKS: 'view_any_scheduled_tasks',
  VIEW_OWN_SCHEDULED_TASKS: 'view_own_scheduled_tasks',
  MODIFY_SCHEDULED_TASKS: 'modify_scheduled_tasks',
  MODIFY_OWN_SCHEDULED_TASKS: 'modify_own_scheduled_tasks',

  // Job Permissions / Project List Permissions
  VIEW_PROJECT_LIST: 'view_project_list',
  VIEW_ANY_CLOSED_JOBS: 'view_any_closed_jobs',
  VIEW_ANY_HIDDEN_JOBS: 'view_any_hidden_jobs',
  MODIFY_JOBS: 'modify_jobs',
  CLOSE_JOBS: 'close_jobs',
  FINISH_JOBS: 'finish_jobs',
  MODIFY_FINISHED_JOBS: 'modify_finished_jobs',
  MODIFY_CLOSED_JOBS: 'modify_closed_jobs',
  UNFINISH_JOBS: 'unfinish_jobs',
  MODIFY_JOB_CARRY_OVER: 'modify_job_carry_over',
  MODIFY_EXTERNAL_COSTS_JOB_ID: 'modify_external_costs_job_id',
  MODIFY_EXTERNAL_COSTS_ON_CLOSED_JOBS: 'modify_external_costs_on_closed_jobs',
  MODIFY_BUDGET_SPLIT: 'modify_budget_split',

  // Basecamp Permissions
  VIEW_BASECAMP: 'view_basecamp',

  // Quote Permissions
  VIEW_ANY_QUOTES: 'view_any_quotes',
  MODIFY_QUOTES: 'modify_quotes',
  EXPORT_QUOTES: 'export_quotes',

  // Briefing Permissions
  VIEW_ANY_BRIEFINGS: 'view_any_briefings',
  MODIFY_BRIEFINGS: 'modify_briefings',
  EXPORT_BRIEFINGS: 'export_briefings',

  // Activity Log Permissions
  VIEW_ANY_ACTIVITY_LOGS: 'view_any_activity_logs',

  // Handbook Permissions
  VIEW_ANY_HANDBOOKS: 'view_any_handbooks',
  MODIFY_HANDBOOKS: 'modify_handbooks',

  // Server Permissions
  VIEW_ANY_SERVERS: 'view_any_servers',
  MODIFY_SERVERS: 'modify_servers',

  // Finance Permissions
  STORE_COMPANY_CASH_SUBMITTED_RECEIPTS: 'store_company_cash_submitted_receipts',
  MODIFY_SUBMITTED_RECEIPTS: 'modify_submitted_receipts',

  // Admin Permissions
  VIEW_NOVA: 'view_nova',
}

export {
  DEFAULT_ROUTE_AFTER_LOGIN,
  DEFAULT_TITLE,
  HEADER_ID,
  DND_TYPES,
  TASK_TYPES,
  TASK_TYPE_IDS,
  QUOTE_STATUSES,
  LEGACY_QUOTE_TOOL_URL,
  HANDBOOK_ARTICLE_ITEM_TYPES,
  ACTIONS,
  EXTERNAL_COSTS_LABELS,
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  JOB_CARRY_OVER_FIELDS,
  LOCAL_STORAGE_LAST_TASKPLAN_GROUP_KEY,
  SUBMITTED_RECEIPT_BOOKING_FORM_FIELDS,
  SUBMITTED_RECEIPT_STATUS,
  SUBMITTED_RECEIPT_STATUS_LABELS,
}
