import qs from 'qs'
import {RouterView, createRouter, createWebHistory} from 'vue-router'

import {ACTIONS, DEFAULT_ROUTE_AFTER_LOGIN, DEFAULT_TITLE} from '@/constants'
import useUsersMeStore from '@/stores/users/me'
import LoginView from '@/views/global/LoginView.vue'

import briefingsRoutes from './briefings'
import financeRoutes from './finance'
import handbookRoutes from './handbook'
import projectsRoutes from './projects'
import timeTrackingRoutes from './time-tracking'

const isFinanceFeatureEnabled = import.meta.env.VITE_FEATURE_FINANCE === 'true'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  parseQuery: (input) => qs.parse(input, {arrayFormat: 'brackets'}),
  stringifyQuery: (input) =>
    qs.stringify(input, {
      arrayFormat: 'brackets',
      encodeValuesOnly: true,
    }),
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }

    // Prevent scrolling when going to or coming from an overlay route
    // as they use a separate scroll container
    if ([...from.matched, ...to.matched].some((record) => record.meta.isOverlay)) {
      return null
    }

    // Prevent scroll when staying on same route with different params and preventScrollOnParamsChange is set
    if (to.name === from.name && to.meta.preventScrollOnParamsChange) {
      return null
    }

    return {top: 0, left: 0}
  },
  routes: [
    {
      path: '/',
      name: 'Login',
      component: LoginView,
      meta: {
        title: 'Login',
      },
    },
    {
      path: '/home',
      name: 'Home',
      redirect: '/',
    },
    {
      // This route is navigated to after login. It does not need a proper view component
      // as login callback is handled in App.vue
      path: '/auth',
      name: 'auth',
      component: RouterView,
    },
    {
      // Route to integrate in the intranet app as iframe
      path: '/handbook/articles/:handbookArticleId(\\d+)/iframe',
      name: 'HandbookArticleIframe',
      component: () => import('@/views/handbook/HandbookArticleIframeView.vue'),
      meta: {
        requiresLogin: true,
      },
    },
    {
      // Holds all the routes that require login
      component: () => import('@/views/global/LoggedInView.vue'),
      path: '/',
      redirect: DEFAULT_ROUTE_AFTER_LOGIN,
      meta: {
        requiresLogin: true,
      },
      children: [
        ...timeTrackingRoutes,
        ...projectsRoutes,
        ...briefingsRoutes,
        ...handbookRoutes,
        ...(isFinanceFeatureEnabled ? financeRoutes : []),
        {
          path: '/team',
          name: 'Team',
          component: () => import('@/views/team/TeamView.vue'),
          meta: {
            title: 'Team',
          },
        },
        {
          path: '/activity-log',
          name: 'ActivityLog',
          component: () => import('@/views/activity-log/ActivityLogView.vue'),
          meta: {
            title: 'Activity Log',
            requiresPermissions: {
              allOf: [ACTIONS.VIEW_ANY_ACTIVITY_LOGS],
            },
          },
          children: [
            {
              path: ':activityId',
              name: 'ActivityLogDetail',
              component: () => import('@/views/activity-log/ActivityLogDetailView.vue'),
              meta: {
                isOverlay: true,
              },
            },
          ],
        },
        {
          path: '/taskplan',
          name: 'Taskplan',
          component: () => import('@/views/taskplan/TaskplanView.vue'),
          meta: {
            title: 'Taskplan',
            requiresPermissions: {
              anyOf: [ACTIONS.VIEW_ANY_SCHEDULED_TASKS, ACTIONS.VIEW_OWN_SCHEDULED_TASKS],
            },
          },
          children: [
            {
              path: 'new/:userId/:date',
              name: 'TaskplanNew',
              component: () => import('@/views/taskplan/TaskplanNewView.vue'),
              meta: {
                title: 'New',
                isOverlay: true,
                requiresPermissions: {
                  anyOf: [ACTIONS.MODIFY_SCHEDULED_TASKS, ACTIONS.MODIFY_OWN_SCHEDULED_TASKS],
                },
              },
            },
            {
              path: 'edit/:scheduledTaskId',
              name: 'TaskplanEdit',
              component: () => import('@/views/taskplan/TaskplanEditView.vue'),
              meta: {
                title: 'Edit',
                isOverlay: true,
                requiresPermissions: {
                  anyOf: [ACTIONS.MODIFY_SCHEDULED_TASKS, ACTIONS.MODIFY_OWN_SCHEDULED_TASKS],
                },
              },
            },
          ],
        },
        {
          path: '/quotes',
          meta: {
            title: 'Quotes',
            requiresPermissions: {
              allOf: [ACTIONS.VIEW_ANY_QUOTES],
            },
          },
          children: [
            {
              path: '',
              name: 'Quotes',
              component: () => import('@/views/quotes/QuotesView.vue'),
              children: [
                {
                  path: 'new',
                  name: 'QuotesNew',
                  component: () => import('@/views/quotes/QuotesNewView.vue'),
                  meta: {
                    title: 'New',
                    isOverlay: true,
                    requiresPermissions: {
                      allOf: [ACTIONS.MODIFY_QUOTES],
                    },
                  },
                },
              ],
            },
            {
              name: 'QuotesEdit',
              path: ':quoteId',
              component: () => import('@/views/quotes/QuotesEditView.vue'),
              meta: {
                title: 'Edit',
                requiresPermissions: {
                  allOf: [ACTIONS.VIEW_ANY_QUOTES],
                },
              },
            },
          ],
        },
        {
          path: '/basecamp',
          name: 'Basecamp',
          component: () => import('@/views/basecamp/BasecampView.vue'),
          meta: {
            title: 'Basecamp',
            requiresPermissions: {
              allOf: [ACTIONS.VIEW_BASECAMP],
            },
          },
        },
        {
          path: '/servers',
          name: 'Servers',
          component: () => import('@/views/servers/ServersView.vue'),
          meta: {
            title: 'Servers',
            requiresPermissions: {
              allOf: [ACTIONS.VIEW_ANY_SERVERS],
            },
          },
        },

        {
          path: '/forbidden',
          name: 'Forbidden',
          component: () => import('@/views/global/ForbiddenView.vue'),
        },

        {
          path: '/:pathMatch(.*)*',
          name: 'NotFound',
          component: () => import('@/views/global/NotFoundView.vue'),
        },
      ],
    },
  ],
})

// eslint-disable-next-line max-statements
router.beforeEach(async (to) => {
  const usersMeStore = useUsersMeStore()

  // Check for user login
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    const isLoggedIn = await usersMeStore.isLoggedIn

    if (!isLoggedIn) {
      return {path: '/', query: {from: to.fullPath}}
    }
  }

  // Check for required permissions
  if (to.matched.some((record) => record.meta.requiresPermissions)) {
    const allOf = to.matched.reduce((acc, record) => {
      if (record.meta.requiresPermissions?.allOf) {
        acc.push(...record.meta.requiresPermissions.allOf)
      }
      return acc
    }, [])

    const anyOf = to.matched.reduce((acc, record) => {
      if (record.meta.requiresPermissions?.anyOf) {
        acc.push(...record.meta.requiresPermissions.anyOf)
      }
      return acc
    }, [])

    const hasPermissions =
      (!allOf.length ||
        (await Promise.all(allOf.map((permission) => usersMeStore.can(permission)))).every(
          Boolean,
        )) &&
      (!anyOf.length ||
        (await Promise.all(anyOf.map((permission) => usersMeStore.can(permission)))).some(Boolean))

    if (!hasPermissions) {
      return {name: 'Forbidden'}
    }
  }

  // Set page title
  const titles = to.matched.map((record) => record.meta.title).filter(Boolean)
  titles.unshift(DEFAULT_TITLE)
  document.title = titles.reverse().join(' – ')

  return true
})

export default router
